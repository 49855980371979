import { useState, Fragment, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import dynamic from "next/dynamic";

const DesktopViewListVilla = dynamic(() => import("./ListVilla/DesktopView"));
const MobileViewListVilla = dynamic(() => import("./ListVilla/MobileView"));

const DesktopViewLastMinute = dynamic(() => import("./LastMinute/DesktopView"));
const MobileViewLastMinute = dynamic(() => import("./LastMinute/MobileView"));

import isMobile from "is-mobile";
import { GetLastMinute, GetVillasByKey } from "./api";
import Link from "next/link";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { useTranslation } from "../context/TranslationContext";
function LastMinuteModal({ page }) {
  const { showAll } = useTranslation();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState(false);
  const isPhone = isMobile();
  const isBannerPage = page.isBanner == "1";

  useEffect(() => {
    if (
      localStorage.getItem("ModalLastUpdated") != page.ModalLastUpdated &&
      page.key != ""
    )
      if (page.key == "LastMinute")
        GetLastMinute()
          .then((r) => {
            setItems(r.data);
          })
          .finally(() => {
            setIsLoaded(true);
          });
      else if (isBannerPage) {
        setItems(page.icerik);
      } else
        GetVillasByKey(page.key)
          .then((r) => {
            setItems(r.data);
          })
          .finally(() => {
            setIsLoaded(true);
          });
  }, []);

  useEffect(() => {
    if (items) setOpen(true);
  }, [items]);

  let [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const CloseModal = () => {
    localStorage.setItem("ModalLastUpdated", page.ModalLastUpdated);
    setOpen(false);
  };

  if (items)
    return (
      <>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            initialFocus={cancelButtonRef}
            onClose={() => null}
            static
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="scale-0"
              enterTo="scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
                {items && (
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel
                      className={`relative rounded-lg bg-white text-left shadow-xl transition-all sm:my-8  px-3 py-5 ${
                        !isBannerPage
                          ? "lg:w-10/12 xl:w-full max-w-[95%]2xl:w-[1280px]"
                          : ""
                      }`}
                    >
                      <button
                        className="absolute -right-3 -top-3 w-9 h-9 rounded-full flex items-center content-center justify-center bg-white text-center outline-none"
                        onClick={CloseModal}
                      >
                        <FaTimes size={24} />
                      </button>
                      {!isBannerPage && (
                        <h3 className="font-bold text-xl w-full text-center lg:text-3xl text-orange  mb-4">
                          {page.baslik}
                          {/* <span className="text-black">Fırsatları !</span> */}
                        </h3>
                      )}
                      <div className="overflow-hidden">
                        {items &&
                          !isBannerPage &&
                          (isPhone ? (
                            <>
                              {page.key != "LastMinute" ? (
                                <MobileViewListVilla data={items} inModal={1} />
                              ) : (
                                <MobileViewLastMinute
                                  items={items}
                                  inModal={1}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              {page.key != "LastMinute" ? (
                                <DesktopViewListVilla
                                  data={items}
                                  inModal={1}
                                />
                              ) : (
                                <DesktopViewLastMinute
                                  items={items}
                                  inModal={1}
                                />
                              )}
                            </>
                          ))}

                        {isBannerPage && (
                          <div
                            className="text-center banner-content"
                            dangerouslySetInnerHTML={{ __html: page.icerik }}
                          ></div>
                        )}
                      </div>
                      {!isBannerPage && (
                        <div className="text-center mt-5">
                          <Link href={"/" + page.url} title={page.title}>
                            <a
                              title={page.title}
                              className="bg-primary font-bold text-sm text-white py-3 px-9 inline-block mx-auto"
                            >
                              {showAll}
                            </a>
                          </Link>
                        </div>
                      )}
                    </Dialog.Panel>
                  </Transition.Child>
                )}
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
}

export default LastMinuteModal;
